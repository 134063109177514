import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'analyticsService',

  components: {
    apiService
  },

  getDaysCollecting(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_DC_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getLastSync(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_LASTSYNC_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getStats(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_STATS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getGStats(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_GSTATS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getData(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_DATA_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getNewData(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_NEW_DATA_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getKPIS(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_KPIS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getPerformance(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_PERFORMANCE_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getBiometrics(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_BIOMETRICS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getGeo(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_GEO_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getGeoAll(params = {}) {
    return apiService
      .get(EndPoints.ANALYTICS_GEOALL_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
