<template>
  <div style="margin: 0; width: 100%; height: 1000px">
    <div class="mt-0">
      <AnalyticsFilter v-on:analytics_change="filter" :show-date="false" />
    </div>

    <div>
      <b-tabs v-model="activeTab" content-class="mt-5" pills>
        <b-tab>
          <template v-slot:title><b-spinner small :variant="activeTab === 0 ? 'white' : 'primary'" v-show="mBusy"></b-spinner> District Level </template>
          <div class="row mb-5">
            <div class="col-lg col-md-12 col-sm-12 mb-4">
              <gmaps-map :options="mapOptions" style="width: 800px; height: 760px">
                <gmaps-info-window ref="infow" :options="infoOptions" @closed="infoWinClosed = true"></gmaps-info-window>
                <gmaps-marker :key="index" v-for="(m, index) in markers" :options="m" @click="toggleInfoWindow(m, index)" />
              </gmaps-map>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title> <b-spinner small :variant="activeTab === 1 ? 'white' : 'primary'" v-show="hBusy"></b-spinner> Community Level Heatmap</template>
          <div class="row">
            <div class="col-lg col-md-12 col-sm-12 mb-4">
              <gmaps-map :options="mapOptions" style="width: 800px; height: 760px">
                <gmaps-heatmap :items="points" />
              </gmaps-map>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { gmapsMap, gmapsMarker, gmapsInfoWindow, gmapsHeatmap } from 'x5-gmaps'

import api from '../../api/analytics'

import PageHeader from '../../containers/PageHeader'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import AnalyticsFilter from '../../components/filters/AnalyticsFilter'

export default {
  name: 'Geo',
  components: {
    gmapsMap,
    gmapsMarker,
    gmapsInfoWindow,
    gmapsHeatmap,
    PageHeader,
    ScaleLoader,
    AnalyticsFilter
  },
  data() {
    return {
      activeTab: 0,
      mBusy: true,
      hBusy: true,
      center: { lat: 9.205749787237584, lng: 0.28202429331848766 },
      markers: [],
      points: [],
      infoWinClosed: true,
      infoOptions: {
        content: '',
        position: { lat: 9.205749787237584, lng: 0.28202429331848766 },
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      currentMidx: null,
      mapOptions: {
        zoom: 7,
        center: { lat: 9.205749787237584, lng: 0.28202429331848766 },
        mapTypeId: 'hybrid'
      },
      list: [],
      filteredList: [],
      heatMapList: [],
      filteredHeatMapList: [],
      firm: 0
    }
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      user: 'getUser'
    })
  },
  watch: {
    filteredList: function () {
      this.markers = this.filteredList.map(function (h) {
        let p = Math.round((h.en_count / h.sn_count) * 100, 0)
        let p2 = Math.round((h.actual / h.expected) * 100, 0)
        let c =
          p2 >= 98
            ? 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            : p2 >= 60
            ? 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
            : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
        let html =
          '<b>District:</b><br />' +
          h.district +
          '<br /><br />' +
          '<b>Firm:</b><br />' +
          h.firm +
          '<br /><br />' +
          '<b>% Coverage (by household):</b><br />' +
          p2 +
          '% (' +
          h.actual +
          '/' +
          h.expected +
          ')<br /><br />' +
          '<b>% Coverage (by structure):</b><br />' +
          p +
          '% (' +
          h.en_count +
          '/' +
          h.sn_count +
          ')<br /><br />'
        return { position: { lng: h.lon, lat: h.lat }, infoText: html, icon: c }
      })
    },
    filteredHeatMapList: function () {
      this.points = this.filteredHeatMapList.map(function (h) {
        return { lng: h.lng, lat: h.lat }
      })
    }
  },
  created() {
    this.firm = this.isGnhr ? 0 : this.user.firm_id
    this.loadData()
    this.loadHeatMapData()
  },
  methods: {
    loadData() {
      const self = this
      self.mBusy = true
      let params = {
        firm: this.isGnhr ? this.firm : this.user !== null ? (this.user.firm_id < 2 ? '' : this.user.firm_id) : 0
      }
      api
        .getGeo(params)
        .then((x) => {
          self.mBusy = false
          self.list = x.geo
          self.filteredList = x.geo
          self.$refs.infow.close()
        })
        .catch((error) => {
          self.mBusy = false
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    loadHeatMapData() {
      const self = this
      self.hBusy = true
      let params = {
        firm: this.isGnhr ? this.firm : this.user !== null ? (this.user.firm_id < 2 ? '' : this.user.firm_id) : 0
      }
      api
        .getGeoAll(params)
        .then((x) => {
          self.hBusy = false
          self.heatMaplist = x.geo
          self.filteredHeatMapList = x.geo
        })
        .catch((error) => {
          self.hBusy = false
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    toggleInfoWindow(marker, idx) {
      this.infoOptions.content = marker.infoText
      this.infoOptions.position = marker.position
      this.center = marker.position

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinClosed = !this.infoWinClosed
        if (this.infoWinClosed) {
          this.$refs.infow.close()
        } else {
          this.$refs.infow.open()
        }
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.$refs.infow.open()
        this.infoWinClosed = false
        this.currentMidx = idx
      }
    },
    filter(v) {
      let self = this
      this.firm = v.firm
      this.filteredList = this.list.filter((e) => {
        return e.firm_id === self.firm || self.firm === 0
      })
      this.filteredHeatMapList = this.heatMapList.filter((e) => {
        return e.firm_id === self.firm || self.firm === 0
      })
    }
  }
}
</script>
<style>
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 800px;
}
</style>
