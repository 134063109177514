<template>
  <div style="margin: 0; padding: 0">
    <div class="mt-1">
      <AnalyticsFilter v-on:analytics_change="filter" />
    </div>

    <div>
      <PageHeader v-if="1 === 0" title="Dashboard" subtitle="Analytics Overview" />

      <div class="row mb-4">
        <div class="col-lg col-md-8 col-sm-8">
          <!-- First row -->
          <div class="row">
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">Avg. Households<br />per day</span>
                      <scale-loader v-if="stats['avg_households_per_day'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['avg_households_per_day'].value }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">Households Covered</span>
                      <scale-loader v-if="stats['households_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['households_covered'].value }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">Population Covered</span>
                      <scale-loader v-if="stats['population_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['population_covered'].value }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">Structures Covered</span>
                      <scale-loader v-if="stats['structures_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['structures_covered'].value }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Second row -->
          <div class="row">
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">% HH <br />Passed QA</span>
                      <scale-loader v-if="stats['perc_hh_passed_qa'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['perc_hh_passed_qa'].value }}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">% HH <br />Covered</span>
                      <scale-loader v-if="stats['perc_hh_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['perc_hh_covered'].value }}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">% Population <br />Covered</span>
                      <scale-loader v-if="stats['perc_pop_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['perc_pop_covered'].value }}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg col-md-3 col-sm-6 mb-3">
              <div class="stats-small stats-small--1 card card-small">
                <div class="card-body p-0 d-flex">
                  <div class="d-flex flex-column m-auto">
                    <div class="stats-small__data text-center">
                      <span class="stats-small__label text-uppercase">% Structures <br />Covered</span>
                      <scale-loader v-if="stats['perc_str_covered'].loading" size="50" color="#dedede"></scale-loader>
                      <h6 v-else class="stats-small__value count my-3">{{ stats['perc_str_covered'].value }}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Sex chart -->
        <div class="col-lg col-md-4 col-sm-4">
          <div class="stats-small stats-small--1 card card-small" style="height: 95%">
            <div class="card-body p-0 d-flex">
              <div class="d-flex flex-column m-auto" style="margin-top: 20px !important">
                <div class="stats-small__data text-center">
                  <span class="stats-small__label text-uppercase">Sex Disaggregation of Population</span>
                  <scale-loader v-if="stats['sex_disaggregation'].loading" size="50" color="#dedede" class="mt-4"></scale-loader>
                  <pie-chart v-else :chart-data="stats['sex_disaggregation'].value" :height="400" style="max-width: 100% !important"></pie-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Num uploads 1 -->
        <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
          <div class="card card-small">
            <div class="card-header border-bottom">
              <h6 class="m-0">Enumerations &amp; QA vs Target</h6>
            </div>
            <div class="card-body pt-0">
              <scale-loader v-if="graphs['standard'].loading" size="50" color="#dedede" class="mt-4"></scale-loader>
              <line-chart v-else :chart-data="graphs['standard'].value" :height="400" style="max-width: 100% !important"></line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Num uploads 2 -->
        <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
          <div class="card card-small">
            <div class="card-header border-bottom">
              <h6 class="m-0">Household Enumeration (Cummulated)</h6>
            </div>
            <div class="card-body pt-0">
              <scale-loader v-if="graphs['cummulative'].loading" size="50" color="#dedede" class="mt-4"></scale-loader>
              <line-chart v-else :chart-data="graphs['cummulative'].value" :height="400" style="max-width: 100% !important"></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
Vue.use(require('vue-moment'))

import PageHeader from '../../containers/PageHeader'
import AnalyticsFilter from '../../components/filters/AnalyticsFilter'

import LineChart from '../../components/charts/LineChart'
import PieChart from '../../components/charts/PieChart'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import api from '../../api/analytics'

export default {
  name: 'Kpi',
  props: {
    info: { type: [Object, Array, Promise] }
  },
  components: {
    PageHeader,
    LineChart,
    PieChart,
    AnalyticsFilter,
    ScaleLoader
  },
  watch: {},
  data() {
    return {
      firm: 0,
      start: '',
      end: '',
      stats: {
        avg_households_per_day: { loading: true, value: 0 },
        households_covered: { loading: true, value: 0 },
        population_covered: { loading: true, value: 0 },
        structures_covered: { loading: true, value: 0 },
        sex_disaggregation: {
          loading: true,
          value: {
            labels: ['Female', 'Male'],
            datasets: [
              {
                label: 'Data One',
                backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)'],
                data: [60, 40]
              }
            ]
          }
        },
        perc_hh_passed_qa: { loading: true, value: 0 },
        perc_hh_covered: { loading: true, value: 0 },
        perc_pop_covered: { loading: true, value: 0 },
        perc_str_covered: { loading: true, value: 0 }
      },
      graphs: {
        standard: {
          loading: true,
          value: {
            labels: [],
            datasets: [
              {
                label: 'QA Passed',
                borderColor: '#00FF00',
                backgroundColor: 'transparent',
                pointRadius: 0,
                lineTension: 0,
                data: []
              },
              {
                label: 'Household Enumeration',
                borderColor: '#007bff',
                backgroundColor: 'transparent',
                pointRadius: 0,
                lineTension: 0,
                data: []
              },
              {
                label: 'Household Target',
                borderColor: '#FF0000',
                backgroundColor: 'transparent',
                pointRadius: 0,
                data: []
              }
            ]
          }
        },
        cummulative: {
          loading: true,
          value: {
            labels: [],
            datasets: [
              {
                label: 'YTD HH Enumeration',
                borderColor: '#007bff',
                backgroundColor: 'transparent',
                pointRadius: 0,
                data: []
              },
              {
                label: 'YTD HH Target',
                borderColor: '#FF0000',
                backgroundColor: 'transparent',
                pointRadius: 0,
                data: []
              }
            ]
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      user: 'getUser'
    })
  },
  created() {
    this.firm = this.isGnhr ? 0 : this.user.firm_id
    this.loadData()
    this.loadNewData()
  },
  methods: {
    loadNewData() {
      const self = this
      Object.keys(this.stats).forEach(function (x) {
        self.doCall(x)
      })
    },
    doCall(type = '') {
      const self = this
      let params = {
        firm: this.isGnhr ? this.firm : this.user !== null ? (this.user.firm_id < 2 ? '' : this.user.firm_id) : 0,
        start: this.start,
        end: this.end,
        type: type
      }
      self.stats[type].loading = true
      api
        .getStats(params)
        .then((x) => {
          self.stats[type].loading = false
          if (type == 'sex_disaggregation') {
            let gbd = x[0].split('-')
            let total = parseInt(gbd[0]) + parseInt(gbd[1])
            let mPerc = total === 0 ? 0 : Math.round((gbd[0] / total) * 100)
            let fPerc = total === 0 ? 0 : 100 - mPerc

            self.stats[type].value = {
              labels: ['Female', 'Male'],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)'],
                  data: [fPerc, mPerc]
                }
              ]
            }
          } else {
            self.stats[type].value = this.cnumbers(x[0])
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    loadData() {
      const self = this
      let params = {
        firm: this.isGnhr ? this.firm : this.user !== null ? (this.user.firm_id < 2 ? '' : this.user.firm_id) : 0,
        start: this.start,
        end: this.end
      }
      this.graphs['standard'].loading = true
      this.graphs['cummulative'].loading = true
      api
        .getNewData(params)
        .then((x) => {
          self.procData(x.uploads, x.targets)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    procData(uploads, targets) {
      const self = this

      // Uploads graph
      let dates = []
      let actualresult = []
      let qaresult = []
      let goalresult = []
      let nc_ar = []
      let nc_qa = []
      let nc_gr = []

      let projectStartDate = this.$moment(new Date()).add(10, 'days').format('YYYY-MM-DD')
      let projectEndDate = '2020-01-01'

      // Set dates
      let uploadsGraph = []
      targets.forEach(function (t) {
        projectStartDate = self.$moment(t.Date).isBefore(projectStartDate) ? t.Date : projectStartDate
        projectEndDate = self.$moment(t.Date).isAfter(projectEndDate) ? t.Date : projectEndDate
        uploadsGraph[self.$moment(t.Date).format('YYYY-MM-DD')] = { goal: t.target, actual: 0, qa: 0 }
      })

      // Consolidate data firm and date
      let info = []
      let seen = []
      uploads.forEach(function (x) {
        let key = x.firm_id + '_' + x.upload_date
        let actualCount = x.actual
        let qaCount = x.qa_passed

        // Setup cut off points for each firm - allows us to remove their goals from the overall goals later.
        if (is.not.existy(info[x.firm_id])) {
          info[x.firm_id] = []
        }
        if (is.inArray(key, seen)) {
          info[x.firm_id][x.upload_date]['actual'] += actualCount
          info[x.firm_id][x.upload_date]['qa'] += qaCount
        } else {
          seen.push(key)
          info[x.firm_id][x.upload_date] = { actual: actualCount, qa: qaCount }
        }
      })

      // Consolidate data by date
      for (let f in info) {
        for (let d in info[f]) {
          if (is.existy(uploadsGraph[d])) {
            uploadsGraph[d]['actual'] += info[f][d].actual
            uploadsGraph[d]['qa'] += info[f][d].qa
          } else {
            if (this.dateIsBefore(d, projectStartDate)) {
              // console.log(d + ' is before ' + projectStartDate)
              uploadsGraph[d] = { goal: 0, actual: info[f][d].actual, qa: info[f][d].qa }
            } else if (this.dateIsAfter(d, projectEndDate)) {
              const keys = Object.keys(uploadsGraph)
              uploadsGraph[d] = { goal: uploadsGraph[keys[keys.length - 1]].goal, actual: info[f][d].actual, qa: info[f][d].qa }
              // console.log(d + ' is after ' + projectEndDate + ' assigning ' + uploadsGraph[keys[keys.length - 1]].goal)
            }
          }
        }
      }

      // Prep for charts
      let prevG = 0
      let prevA = 0
      let prevQ = 0
      let y = this.sortObject(uploadsGraph)
      for (let k in y) {
        let bCheck = this.start != '' && this.start != null ? this.dateIsAfter(k, this.start) : true
        let aCheck = this.end != '' && this.end != null ? this.dateIsBefore(k, this.end) : true

        if (aCheck && bCheck) {
          dates.push(this.$moment(k).format('MMM Do, YYYY'))
          nc_ar.push(uploadsGraph[k].actual)
          nc_qa.push(uploadsGraph[k].qa)
          nc_gr.push(uploadsGraph[k].goal)
          actualresult.push(uploadsGraph[k].actual + prevA)
          qaresult.push(uploadsGraph[k].qa + prevQ)
          goalresult.push(this.dateIsAfter(k, projectEndDate) ? prevG : uploadsGraph[k].goal + prevG)
        }
        prevG = this.dateIsAfter(k, projectEndDate) ? prevG : uploadsGraph[k].goal + prevG
        prevA = uploadsGraph[k].actual + prevA
        prevQ = uploadsGraph[k].qa + prevQ
      }

      this.graphs['cummulative'].value = {
        labels: dates,
        datasets: [
          {
            label: 'HH Enumeration',
            borderColor: '#007bff',
            backgroundColor: 'transparent',
            pointRadius: 0,
            lineTension: 0,
            data: actualresult
          },
          {
            label: 'HH Target',
            borderColor: '#FF0000',
            backgroundColor: 'transparent',
            pointRadius: 0,
            lineTension: 0,
            data: goalresult
          }
        ]
      }

      this.graphs['standard'].value = {
        labels: dates,
        datasets: [
          {
            label: 'QA Passed',
            borderColor: '#00FF00',
            backgroundColor: 'transparent',
            pointRadius: 0,
            lineTension: 0,
            data: nc_qa
          },
          {
            label: 'Household Enumeration',
            borderColor: '#007bff',
            backgroundColor: 'transparent',
            pointRadius: 0,
            lineTension: 0,
            data: nc_ar
          },
          {
            label: 'Household Target',
            borderColor: '#FF0000',
            backgroundColor: 'transparent',
            pointRadius: 0,
            lineTension: 0,
            data: nc_gr
          }
        ]
      }

      this.graphs['standard'].loading = false
      this.graphs['cummulative'].loading = false
    },
    sortObject(o) {
      var sorted = {},
        key,
        a = []

      for (key in o) {
        if (o.hasOwnProperty(key)) {
          a.push(key)
        }
      }

      a.sort()

      for (key = 0; key < a.length; key++) {
        sorted[a[key]] = o[a[key]]
      }
      return sorted
    },
    dateIsBefore(d1, d2) {
      return this.$moment(d1).isBefore(d2)
    },
    dateIsAfter(d1, d2) {
      return this.$moment(d1).isAfter(d2)
    },
    getDatesRangeArray(startDate, enddate, interval) {
      var cfg = { interval: interval || 'days' }
      let start = this.$moment(startDate)
      let end = this.$moment(enddate)
      var dateArray = []
      var currentDate = start
      // console.log('-->', currentDate._i, '<=', enddate._i, currentDate <= enddate)
      while (currentDate <= end) {
        dateArray.push(currentDate.format('YYYY-MM-DD'))
        currentDate = currentDate.add(1, cfg.interval)
      }

      return dateArray
    },
    filter(v) {
      this.firm = v.firm
      this.start = is.not.undefined(v.period) && is.array(v.period) ? v.period[0] : ''
      this.end = is.not.undefined(v.period) && is.array(v.period) ? v.period[1] : ''
      this.loadData()
      this.loadNewData()
    }
  }
}
</script>
<style>
.stats-small--1 .stats-small__label {
  font-size: 0.65rem !important;
}
.stats-small--1 .stats-small__value {
  font-size: 1.2rem !important;
}
.stats-small--1 canvas {
  opacity: 1 !important;
}
</style>
