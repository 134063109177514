<template lang="pug">
   extends ../BaseView.pug
</template>

<script>
import Vue from 'vue'
import BaseView from '../BaseView'

import Kpis from './Kpi'
import Performance from './Performance'
import Geo from './Geo'

Vue.component('kpis', { template: '<Kpis />' })
Vue.component('performance', { template: '<Performance />' })
Vue.component('geo', { template: '<Geo />' })

export default {
  name: 'Analytics',
  extends: BaseView,
  components: {
    Kpis,
    Performance,
    Geo
  },
  props: {
    pages: {
      type: Array,
      default: () => {
        return [
          {
            tabName: 'kpis',
            component: 'kpis'
          },
          {
            tabName: 'performance',
            component: 'performance'
          },
          {
            tabName: 'geo',
            component: 'geo'
            // props: { 'info': info }
          }
        ]
      }
    }
  }
}
</script>
