<template>
  <div>
    <div class="row">
      <div v-if="canViewFirm" class="col-md-3 col-sm-3">
        <FirmFilter v-on:firm_change="filterByFirm" :labelPosition="'top'" />
      </div>

      <div class="col-md-4 col-sm-4" v-if="showDate">
        <label style="font-weight: bold; padding-right: 15px">Data Collection Date</label><br />
        <el-date-picker
          v-model="period"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="col-md-2 col-sm-2" v-if="showDate">
        <b-form-group id="days_of_c" label="Days of Collection" label-class="font-weight-bolder" label-cols="top" label-for="days_of_c">
          <div class="text-center card-small" style="background-color: #fff; border-radius: 5px; padding: 5px">
            <span class="stats-small__value count my-3" style="font-size: 1rem; font-weight: 600" v-html="daysOfC"></span>
          </div>
        </b-form-group>
      </div>
      <div class="col-md-3 col-sm-3" v-if="showDate">
        <b-form-group id="last_sync" label="Last Sync Date" label-class="font-weight-bolder" label-cols="top" label-for="last_sync">
          <div class="text-center card-small" style="background-color: #fff; border-radius: 5px; padding: 5px">
            <span class="py-3 my-3" style="font-size: 1rem; font-weight: 600" v-html="lastSync"></span>
          </div>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg col-md-4 col-sm-4 mb-3">
        <hr />
      </div>
    </div>

    <!-- </b-collapse> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'

import FirmFilter from './FirmFilter'
import api from '../../api/analytics'

export default {
  name: 'AnalyticsCombo',
  components: {
    FirmFilter
  },
  props: {
    labelPosition: { type: String, default: 'left' },
    showDate: { type: Boolean, default: true }
  },
  data: () => ({
    firm: 0,
    region: 0,
    district: 0,
    community: 0,
    coordinator: 0,
    supervisor: 0,
    enumerator: 0,
    period: '',
    daysOfC: 0,
    lastSync: '',
    pickerOptions: {
      shortcuts: [
        {
          text: 'Last week',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Last month',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ],
      disabledDate: function (d) {
        let current = new Date()
        return d > current
      }
    }
  }),
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      isAdmin: 'isAdmin',
      isOps: 'isOperations',
      isCoordinator: 'isCoordinator',
      isSupervisor: 'isSupervisor',
      isEnumerator: 'isEnumerator'
    }),
    canViewFirm: function () {
      return this.isGnhr
    },
    canViewRegion: function () {
      return this.isOperations || this.isAdmin
    },
    canViewDistrict: function () {
      return this.isOperations || this.isAdmin
    },
    canViewCommunity: function () {
      return this.isOperations || this.isAdmin
    },
    canViewCoordinator: function () {
      return this.isGnhr || this.isOperaions || this.isAdmin
    },
    canViewSupervisor: function () {
      return this.isGnhr || this.isOperations || this.isCoordinator || this.isAdmin
    },
    canViewEnumerator: function () {
      return this.isGnhr || this.isOperations || this.isCoordinator || this.isSupervisor || this.isAdmin
    },
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 'top'
    }
  },
  watch: {
    period: function () {
      this.sendUpdate()
    }
  },
  created() {
    this.getDaysCollecting()
    this.getLastSyncDate()
  },
  methods: {
    getDaysCollecting() {
      let self = this
      api
        .getDaysCollecting({ firm: this.firm })
        .then((x) => {
          self.daysOfC = x[0]
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    getLastSyncDate() {
      let self = this
      api
        .getLastSync({ firm: this.firm })
        .then((x) => {
          self.lastSync = self.$moment(x[0]).format('ddd, MMM Do, YYYY')
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    filterByFirm(v) {
      this.firm = v.value
      this.getLastSyncDate()
      this.getDaysCollecting()
      this.sendUpdate()
    },
    filterByRegion(v) {
      this.region = v.value
      this.sendUpdate()
    },
    filterByDistrict(v) {
      this.district = v.value
      this.sendUpdate()
    },
    filterByCommunity(v) {
      this.community = v.value
      this.sendUpdate()
    },
    filterByCoordinator(v) {
      this.coordinator = v.value
      this.sendUpdate()
    },
    filterBySupervisor(v) {
      this.supervisor = v.value
      this.sendUpdate()
    },
    filterByEnumerator(v) {
      this.enumerator = v.value
      this.sendUpdate()
    },
    // getDefaultStartDate(asDate = true) {
    //   const start = new Date()
    //   start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    //   return asDate ? start : start.toDateString()
    // },
    // getDefaultEndDate(asDate = true) {
    //   return asDate ? new Date() : new Date().toDateString()
    // },
    // dateDiffInDays(a, b) {
    //   const _MS_PER_DAY = 1000 * 60 * 60 * 24
    //   const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    //   const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

    //   return Math.floor((utc2 - utc1) / _MS_PER_DAY)
    // },
    sendUpdate() {
      this.$emit('analytics_change', {
        firm: this.firm,
        region: this.region,
        district: this.district,
        community: this.community,
        coordinator: this.coordinator,
        supervisor: this.supervisor,
        enumerator: this.enumerator,
        period: this.period
      })
    }
  }
}
</script>
