<template>
  <div style="margin: 0; padding: 0">
    <div class="mt-1">
      <AnalyticsFilter v-on:analytics_change="filter" :show-date="false" />
    </div>

    <div v-if="loading">
      <div class="row" style="margin-top: 100px">
        <div class="col-lg col-md-6 col-sm-6 mb-4">
          <scale-loader size="200" color="#007bff"></scale-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-lg col-md-6 col-sm-6 mb-4">
          <p class="text-center"><strong>Loading from database...</strong></p>
        </div>
      </div>
    </div>

    <div v-else>
      <b-card no-body class="mb-5">
        <b-tabs card pills fill>
          <b-tab title="Firm Enumeration" active>
            <div class="row">
              <div class="col-lg col-md-12 col-sm-12 mb-4">
                <AnalyticsPerformance type="firm" title="Firm Enumeration" :info.sync="firmList" :end-date="end" />
              </div>
            </div>
          </b-tab>
          <b-tab title="District Enumeration">
            <div class="row">
              <div class="col-lg col-md-12 col-sm-12 mb-4">
                <AnalyticsPerformance type="district" title="District Enumeration" :info.sync="districtList" :end-date="end" />
              </div>
            </div>
          </b-tab>
          <b-tab title="Community Enumeration">
            <div class="row">
              <div class="col-lg col-md-12 col-sm-12 mb-4">
                <AnalyticsBiometrics type="community" title="Community Enumeration" :info.sync="communityList" />
              </div>
            </div>
          </b-tab>
          <b-tab title="Enumerators Performance">
            <div class="row">
              <div class="col-lg col-md-12 col-sm-12 mb-4">
                <AnalyticsPerformance type="enumerator" title="Enumerators Performance" :info.sync="enumeratorList" :end-date="end" />
              </div>
            </div>
          </b-tab>
          <b-tab title="Biometrics">
            <div class="row">
              <div class="col-lg col-md-12 col-sm-12 mb-4">
                <AnalyticsBiometrics type="biometric" title="Biometrics" :info.sync="biometricList" />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'

import PageHeader from '../../containers/PageHeader'
import AnalyticsFilter from '../../components/filters/AnalyticsFilter'

import AnalyticsPerformance from '../../components/tables/AnalyticsPerformance'
import AnalyticsBiometrics from '../../components/tables/AnalyticsBiometrics'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

import api from '../../api/analytics'

export default {
  name: 'Performance',
  components: {
    ScaleLoader,
    PageHeader,
    AnalyticsPerformance,
    AnalyticsBiometrics,
    AnalyticsFilter
  },
  data() {
    return {
      firmList: [],
      districtList: [],
      communityList: [],
      enumeratorList: [],
      biometricList: [],
      firmLoaded: false,
      districtLoaded: false,
      communityLoaded: false,
      enumeratorLoaded: false,
      biometricLoaded: false,
      firm: 0,
      start: '',
      end: ''
    }
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      user: 'getUser'
    }),
    loading() {
      return !(this.firmLoaded && this.districtLoaded && this.communityLoaded && this.enumeratorLoaded && this.biometricLoaded)
    }
  },
  created() {
    this.firm = this.isGnhr ? 0 : this.user.firm_id
    this.loadData()
  },
  methods: {
    loadData() {
      const self = this
      const types = ['firm', 'district', 'community', 'enumerator', 'biometric']
      let params = {
        firm: this.isGnhr ? this.firm : this.user !== null ? (this.user.firm_id < 2 ? '' : this.user.firm_id) : 0,
        type: '',
        start: this.start,
        end: this.end
      }
      this.firmLoaded = false
      this.districtLoaded = false
      this.communityLoaded = false
      this.enumeratorLoaded = false
      this.biometricLoaded = false
      types.forEach(function (t) {
        params['type'] = t
        api
          .getNewData(params)
          .then((response) => {
            return self.setList(t, response)
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
            self.setList(t, { kpi: [] })
          })
      })
    },
    setList(type, response) {
      if (type === 'firm') {
        this.firmList = response.kpi
        this.firmLoaded = true
      } else if (type === 'district') {
        this.districtList = response.kpi
        this.districtLoaded = true
      } else if (type === 'community') {
        this.communityList = response.kpi
        this.communityLoaded = true
      } else if (type === 'enumerator') {
        this.enumeratorList = response.kpi
        this.enumeratorLoaded = true
      } else if (type === 'biometric') {
        this.biometricList = response.kpi
        this.biometricLoaded = true
      }
    },
    filter(v) {
      this.firm = v.firm
      this.start = is.not.undefined(v.period) && is.array(v.period) ? v.period[0].toDateString() : ''
      this.end = is.not.undefined(v.period) && is.array(v.period) ? v.period[1].toDateString() : ''
      this.loadData()
    }
  }
}
</script>
