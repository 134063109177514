<template>
  <div style="margin: 0; padding: 0">
    <div class="row">
      <div class="col">
        <div class="cad crd-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-12" style="border: 0px solid red">
                <b-button @click="csvExport" class="float-right" variant="outline-primary"> <i class="material-icons">cloud_download</i> Export</b-button>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" :table-props="tableProps">
              <el-table-column v-for="(item, idx) in visibleFields" :key="idx" :prop="item.prop" :label="item.label" :sortable="item.sortable" :width="item.width">
                <template slot-scope="scope">
                  <span v-if="item.prop === 'performance'">
                    <b-badge v-if="scope.row[item.prop] < cutoff" variant="danger">{{ scope.row[item.prop] }}%</b-badge>
                    <b-badge v-else variant="success">{{ scope.row[item.prop] }}%</b-badge>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { DataTables } from 'vue-data-tables'

Vue.use(DataTables)
Vue.use(require('vue-moment'))

export default {
  name: 'AnalyticsPerformance',
  props: {
    type: { type: String, default: 'firm' },
    info: { type: Array, default: null },
    endDate: { type: String, default: null }
  },
  data() {
    return {
      busy: true,
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['firm', 'district']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No responses found',
        stripe: true,
        defaultSort: { order: 'ascending', prop: 'district' }
      }
    }
  },
  created() {
    this.setTableFields()
    this.get()
  },
  watch: {
    info: function () {
      this.get()
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isGnhr: 'isGnhr'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    filename: function () {
      let x = ''
      switch (this.type) {
        case 'firm':
          x = 'FOMS Analytics - Firm Performance Data.csv'
          break
        case 'district':
          x = 'FOMS Analytics - District Performance Data.csv'
          break
        case 'enumerator':
          x = 'FOMS Analytics - Enumerator Performance Data.csv'
          break
        default:
          x = 'FOMS Analytics - Performance Data.csv'
      }
      return x
    }
  },
  methods: {
    setTableFields() {
      let utc = new Date().toJSON().slice(0, 10)
      utc = is.empty(this.endDate) || is.null(this.endDate) || is.undefined(this.endDate) ? this.$moment(utc).format('MMM Do, YYYY') : this.$moment(this.endDate).format('MMM Do, YYYY')

      if (this.isGnhr) {
        this.tableFields.push({ prop: 'firm', label: 'Firm', visible: true, sortable: true, width: '200' })
      }

      if (this.type === 'firm' || this.type === 'district') {
        this.tableFields.push({ prop: 'district', label: 'District', visible: this.type === 'district', sortable: true })
        this.tableFields.push({ prop: 'estimated_households', label: 'No. of Estimated HHs', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_households', label: 'No. of HH Synced as at ' + utc, visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'members_synced', label: 'Total Members Synced', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'households_qaed', label: 'HHs QAed', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'perc_ehh_completed', label: '% of Estimated HHs Enumerated', visible: true, sortable: false })
        this.tableFields.push({ prop: 'perc_completed', label: '% of Estimated HHs QA Complete', visible: true, sortable: false })
        this.tableFields.push({ prop: 'num_with_docs', label: 'HHs with Photos', visible: true, sortable: true, numeric: true })
      } else {
        // this.tableFields.push({ prop: 'userID', label: 'User ID', visible: false, sortable: false })
        this.tableFields.push({ prop: 'enumerator', label: 'Enumerator', visible: true, sortable: true })
        this.tableFields.push({ prop: 'num_households_enumerated', label: 'No. of HH Enumerated', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_households_refused', label: 'HH Refused', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_households_absent', label: 'HH Absent', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'perc_passed_qa', label: '%HH Passed QA', visible: true, sortable: false })
        this.tableFields.push({ prop: 'num_errors', label: 'No. with Errors', visible: true, sortable: true, numeric: true })
        // this.tableFields.push({ prop: 'perc_members_with_bio', label: '% Members with Biometrics', visible: true, sortable: false })
        this.tableFields.push({ prop: 'avg_households_per_day', label: 'Avg HH Enumerated Per Day', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'avg_members_per_day', label: 'Avg HH members Enumerated Per Day', visible: true, sortable: true, numeric: true })
      }
    },
    get() {
      this.busy = true
      this.loadTable(this.info)
    },
    loadTable(response) {
      let self = this
      this.tableData = response.map(function (s) {
        s.perc_ehh_completed = (s.estimated_households === 0 || is.null(s.estimated_households) ? 0 : Math.ceil((s.num_households / s.estimated_households) * 100, 2)) + '%'
        s.perc_completed = (s.estimated_households === 0 || is.null(s.estimated_households) ? 0 : Math.ceil((s.households_qaed / s.num_households) * 100)) + '%'
        s.perc_passed_qa = (s.in_qa === 0 || is.null(s.in_qa) ? 0 : Math.round((s.qa_passed / s.in_qa) * 100, 2)) + '%'
        // s.perc_members_with_bio = (s.num_members_synced === 0 || is.null(s.num_members_synced)) ? 0 : Math.round((s.num_households_with_bio / s.num_members_synced) * 100, 2)
        s = self.getSimpleRow(s)
        return s
      })
      self.tableTotal = self.tableData.length
      self.busy = false
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && i.numeric === true ? this.cnumbers(x[i.prop]) : x[i.prop]
        x[i.prop] = is.null(x[i.prop]) ? 0 : x[i.prop]
      }
      return x
    },
    csvExport() {
      let csvContent = 'data:text/csv;charset=utf-8'
      for (let t in this.visibleFields) {
        csvContent += ',' + this.visibleFields[t].label
      }
      csvContent += '\n'
      csvContent += this.tableData
        .map(function (d) {
          let x = ''
          for (let y in d) {
            let u = d[y].replace(/,/g, '')
            x += u + ','
          }
          return x
        })
        .join('\n')
        .replace(/(^\{)|(\}$)/gm, '')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', this.filename)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
<style>
.el-table th > .cell {
  font-size: 11px !important;
  word-break: break-word;
}
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
