<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  mixins: [Pie, reactiveProp],
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            padding: 25,
            boxWidth: 20,
            usePointStyle: true
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex]
              let currentValue = dataset.data[tooltipItem.index]
              return ' ' + currentValue + '%'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
