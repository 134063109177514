<template>
  <div style="margin:0; padding:0">

    <div class="row">
      <div class="col">
        <div class="cad crd-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-12" style="border:0px solid red">
                <b-button @click="csvExport" class="float-right" variant="outline-primary">
                  <i class="material-icons">cloud_download</i> Export</b-button>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables
              :loading="busy"
              :data="tableData"
              :total="tableTotal"
              :filters="tableFilteredData"
              :table-props="tableProps">
              <el-table-column
                v-for="(item, idx) in visibleFields"
                :key="idx"
                :prop="item.prop"
                :label="item.label"
                :sortable="item.sortable"
                :width="item.width">
                <template slot-scope="scope">
                  <span v-if="item.prop === 'performance'">
                    <b-badge v-if="scope.row[item.prop] < cutoff" variant="danger">{{ scope.row[item.prop] }}%</b-badge>
                    <b-badge v-else variant="success">{{ scope.row[item.prop] }}%</b-badge>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { DataTables } from 'vue-data-tables'

Vue.use(DataTables)
Vue.use(require('vue-moment'))

export default {
  name: 'AnalyticsBiometrics',
  props: {
    type: { type: String, default: 'biometric' },
    info: { type: Array }
  },
  data() {
    return {
      busy: true,
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['firm', 'district']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No responses found',
        stripe: true,
        defaultSort: { order: 'ascending', prop: 'district' }
      },
    }
  },
  created() {
    this.setTableFields()
    this.get()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isGnhr: 'isGnhr'
    }),
    visibleFields: function() {
      return this.tableFields.filter(function(f) {
        return f.visible
      })
    },
    filename: function() {
      return this.type === 'biometric' ? 'FOMS Analytics - Biometric.csv' : 'FOMS Analytics - Communities.csv'
    }
  },
  watch: {
    info: function () {
      this.get()
    }
  },
  methods: {
    setTableFields () {
      if (this.isGnhr) {
        this.tableFields.push({ prop: 'firm', label: 'Firm', visible: true, sortable: true, width: '200' })
      }

      this.tableFields.push({ prop: 'district', label: 'District', visible: true, sortable: true })

      if (this.type === 'biometric') {
        this.tableFields.push({ prop: 'members_enumerated', label: 'Eligible HH Members', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_biometrics', label: 'With Biometrics', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_without_biometrics', label: 'Without Biometrics', visible: true, sortable: true, numeric: true })
      } else {
        this.tableFields.push({ prop: 'num_communities', label: 'No of Communities', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_communities_unenumerated', label: 'Un-Enumerated', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_communities_inprogress', label: 'In Progress', visible: true, sortable: true, numeric: true })
        this.tableFields.push({ prop: 'num_communities_completed', label: 'Completed', visible: true, sortable: true, numeric: true })
      }
    },
    get() {
      this.busy = true
      this.loadTable(this.info)
    },
    loadTable (response) {
      let self = this
      self.tableData = response.map(function(s) {
        return self.getSimpleRow(s)
      })
      self.tableTotal = self.tableData.length
      self.busy = false
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date)
          ? this.$moment(item[i.prop]).format('MMM Do, YYYY')
          : item[i.prop]
        x[i.prop] = is.existy(i.numeric)
          ? this.cnumbers(item[i.prop])
          : x[i.prop]
      }
      return x
    },
    csvExport () {
      let csvContent = 'data:text/csv;charset=utf-8'
      for (let t in this.visibleFields) {
        csvContent += ',' + this.visibleFields[t].label
      }
      csvContent += '\n'
      csvContent += this.tableData.map(function (d) {
        let x = ''
        for(let y in d) {
          let u = d[y].replace(/,/g, '')
          x += u + ','
        }
        return x
      }).join('\n').replace(/(^\{)|(\}$)/mg, '')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', this.filename)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
