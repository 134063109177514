<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  mixins: [Line, reactiveProp],
  data() {
    return {
      options: {
        //Chart.js options
        type: 'line',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              // type: 'time',
              // time: {
              //   displayFormats: {
              //     week: 'ii'
              //   }
              // },
              distribution: 'series',
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            padding: 25,
            boxWidth: 20,
            usePointStyle: true
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
